@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  height: 100%;
}

.fullHeight {
  height: 100%;
}

.verticallyCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.smallForm {
  width: 50%;
  min-width: 330px;
  max-width: 500px;
  align-self: center;
}
